exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ios-js": () => import("./../../../src/pages/ios.js" /* webpackChunkName: "component---src-pages-ios-js" */),
  "component---src-pages-lulu-99-js": () => import("./../../../src/pages/lulu99.js" /* webpackChunkName: "component---src-pages-lulu-99-js" */),
  "component---src-pages-play-js": () => import("./../../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-plugin-js": () => import("./../../../src/pages/plugin.js" /* webpackChunkName: "component---src-pages-plugin-js" */),
  "component---src-pages-watch-2-earn-js": () => import("./../../../src/pages/watch2earn.js" /* webpackChunkName: "component---src-pages-watch-2-earn-js" */),
  "component---src-pages-xgrant-js": () => import("./../../../src/pages/xgrant.js" /* webpackChunkName: "component---src-pages-xgrant-js" */)
}

